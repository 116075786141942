.ant-message .anticon {
   top: -2px !important;
   margin-right: 10px !important;
}
.anticon path {
   background-color: #fff!important;
   fill: #fff!important;
}

.ant-message > div > div {
   position: fixed;
   bottom: 50%;
   left: 20%;
   right: 20%;
}
.ant-message > div > div >div {
   padding: 14px 26px;
   border-radius: 8px;
   margin-top: 8%;
   background-color: #FF5732;
   color: #fff;
   font-family: 'Pretendard';
   font-size: 14px;
   font-weight: 500;
   line-height: 24px;
}

.copy-menu-modal .ant-transfer-customize-list tr {
   cursor: pointer;
}

.ant-checkbox-inner-ow::after {
   top: 6px;
   left: 3px;
}

.ant-table-row .complete-copy-row {
   position: absolute;
   top: 0;
   left: 0px;
   background-color: rgba(177, 234, 177, 0.4);
   width: 100%;
   height: 100%;
   z-index: 10;
   line-height: 40px;
   font-size: 14px;
   padding-left: 8px;
   color: black;
}

.ant-table-row .complete-copy-row span {
   position: absolute;
   top: 0;
   right: 20px;
   display: block;
   color: #265e21;
   font-weight: 600;
}

.ant-table-row .complete-copy-row.hidden {
   display: none;
}

.ant-table-row .loading-copy-row {
   position: absolute;
   top: 0;
   right: 20px;
   line-height: 32px;
}

.ant-table-row .loading-copy-row.hidden {
   display: none;
}

.ant-table-row .truncate-menu-button {
   position: absolute;
   top: 0;
   right: 20px;
   line-height: 36px;
}

.ant-table-row .store-status-row {
   display: inline-block
}