@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);
@import url(https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css);
@import url(https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css);


p, a, h1, h2, h3, h4, h5, h6, button {
    -webkit-transform: skew(-0.1deg);
            transform: skew(-0.1deg);
    color: #000;
    font-family: 'NanumSquare';
}
.accordionContent {
    overflow: hidden;
}

/* 제목 */
.aTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 26px 40px;
}
.accordionSection button {
    width: 100%;
    margin-bottom: 12px;
    padding: 0;
    outline: none;
    border: none;
    background-color: #fff;
    border: 1px solid #ECEEF2;  
    text-align: left;
    border-radius: 4px;
}
.accordionSection button.active {
    background-color: #F8F9FA;
}
.accordionTitle {
    margin: 0;
    font-size: 18px;
    font-weight: 900;
    line-height: 36px;
    color: #000!important;
}

/* 내용 */
.accordionText  {
    font-family: 'NanumSquare';
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    color: #000;
    background-color: #F8F9FA;
    padding: 20px 40px 46px 40px;
}
.accordionContent {
    width: 100%;
    white-space: pre-line;
    word-break: keep-all;
    margin: 0;
}


.ant-message .anticon {
   top: -2px !important;
   margin-right: 10px !important;
}
.anticon path {
   background-color: #fff!important;
   fill: #fff!important;
}

.ant-message > div > div {
   position: fixed;
   bottom: 50%;
   left: 20%;
   right: 20%;
}
.ant-message > div > div >div {
   padding: 14px 26px;
   border-radius: 8px;
   margin-top: 8%;
   background-color: #FF5732;
   color: #fff;
   font-family: 'Pretendard';
   font-size: 14px;
   font-weight: 500;
   line-height: 24px;
}

.copy-menu-modal .ant-transfer-customize-list tr {
   cursor: pointer;
}

.ant-checkbox-inner-ow::after {
   top: 6px;
   left: 3px;
}

.ant-table-row .complete-copy-row {
   position: absolute;
   top: 0;
   left: 0px;
   background-color: rgba(177, 234, 177, 0.4);
   width: 100%;
   height: 100%;
   z-index: 10;
   line-height: 40px;
   font-size: 14px;
   padding-left: 8px;
   color: black;
}

.ant-table-row .complete-copy-row span {
   position: absolute;
   top: 0;
   right: 20px;
   display: block;
   color: #265e21;
   font-weight: 600;
}

.ant-table-row .complete-copy-row.hidden {
   display: none;
}

.ant-table-row .loading-copy-row {
   position: absolute;
   top: 0;
   right: 20px;
   line-height: 32px;
}

.ant-table-row .loading-copy-row.hidden {
   display: none;
}

.ant-table-row .truncate-menu-button {
   position: absolute;
   top: 0;
   right: 20px;
   line-height: 36px;
}

.ant-table-row .store-status-row {
   display: inline-block
}
