p, a, h1, h2, h3, h4, h5, h6, button {
    transform: skew(-0.1deg);
    color: #000;
    font-family: 'NanumSquare';
}
.accordionContent {
    overflow: hidden;
}

/* 제목 */
.aTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 26px 40px;
}
.accordionSection button {
    width: 100%;
    margin-bottom: 12px;
    padding: 0;
    outline: none;
    border: none;
    background-color: #fff;
    border: 1px solid #ECEEF2;  
    text-align: left;
    border-radius: 4px;
}
.accordionSection button.active {
    background-color: #F8F9FA;
}
.accordionTitle {
    margin: 0;
    font-size: 18px;
    font-weight: 900;
    line-height: 36px;
    color: #000!important;
}

/* 내용 */
.accordionText  {
    font-family: 'NanumSquare';
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    color: #000;
    background-color: #F8F9FA;
    padding: 20px 40px 46px 40px;
}
.accordionContent {
    width: 100%;
    white-space: pre-line;
    word-break: keep-all;
    margin: 0;
}

